import { PipelineAction, PipelineFailure, PipelineState } from "./pipeline-types";

const initialState: PipelineState = {
  failures: {
    loading: false,
    error: null,
    list: [] as PipelineFailure[],
  },
};

export function pipelineReducer(
  state: PipelineState = initialState,
  action: PipelineAction
): PipelineState {
  switch (action.type) {
    case "PIPELINE_FAILURES_START_LOADING_DATA":
      return {
        ...state,
        failures: {
          ...state.failures,
          loading: true,
        },
      };
    case "PIPELINE_FAILURES_SET_DATA":
      return {
        ...state,
        failures: {
          loading: false,
          error: null,
          list: action.payload.list,
        },
      };
    case "PIPELINE_FAILURES_ERROR":
      return {
        ...state,
        failures: {
          loading: false,
          error: action.payload.message,
          list: [],
        },
      };
    default:
      return state;
  }
}
