import { useEffect, useState } from "react";
import {
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { Refresh as IconRefresh, Error as IconError } from "@material-ui/icons";

import FlexCol from "../../components/common/FlexCol";
import { SpinnerInnovasea, TableSearchBar } from "../../fathom-brella";
import FlexRow from "../../components/common/FlexRow";
import { Title } from "../../components/common/typography";
import DialogWrapper from "../../components/common/DialogWrapper";

import { useSelectorTyped as useSelector, useThunkDispatch } from "../../redux/common";
import { queryPipelineFailures } from "../../redux/admin/pipeline/pipeline-actions";
import { FailedFile, PipelineFailure } from "../../redux/admin/pipeline/pipeline-types";
import { FailedFilesTable } from "../../components/admin/pipeline/FailedFilesTable";

export function Pipeline() {
  const dispatch = useThunkDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [foundCount, setFoundCount] = useState<number>(0);
  const [showResolved, setShowResolved] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<PipelineFailure[]>([]);

  useEffect(() => {
    dispatch(queryPipelineFailures());
  }, [dispatch]);

  const { pipelineFailedFiles, pipelineFailuresError, pipelineFailuresLoading } = useSelector(
    ({ adminPipeline }) => {
      const pipelineFailuresLoading = adminPipeline.failures.loading;
      const pipelineFailuresError = adminPipeline.failures.error;
      const pipelineFailuresList = adminPipeline.failures.list;
      const pipelineFailedFiles = [] as FailedFile[];

      // group the pipeline failures by fileId:
      const fileFailureMap: { [key: string]: PipelineFailure[] } = {};
      pipelineFailuresList.forEach(pipelineFailure => {
        if (!fileFailureMap[pipelineFailure.fileId]) {
          fileFailureMap[pipelineFailure.fileId] = [];
        }
        fileFailureMap[pipelineFailure.fileId].push(pipelineFailure);
      });
      // process failure messages per fileId:
      const fileIds = Object.keys(fileFailureMap);
      fileIds.forEach(fileId => {
        const history = fileFailureMap[fileId];
        const workspaces = {};
        // sort the failures per file by updateTime descending
        history.sort((t1, t2) =>
          t1.updateTime == t2.updateTime ? 0 : t1.updateTime > t2.updateTime ? -1 : 1
        );
        // list workspaces the file is in:
        history.forEach(f => (workspaces[f.workspaceId] = 1));

        const mostRecentFailure = history[0];
        // check if the most recent processing of the file has failed:
        if (
          !mostRecentFailure.mergeInfo ||
          mostRecentFailure.mergeInfo.mergeStatus.phaseStatus == "FAILED" ||
          showResolved
        ) {
          // if it has, add it to the failed files list along with history of failures:
          pipelineFailedFiles.push({
            ...mostRecentFailure,
            workspaces: Object.keys(workspaces).join(", "),
            history,
          });
        }
      });

      return {
        pipelineFailuresLoading,
        pipelineFailuresError,
        pipelineFailedFiles,
      };
    }
  );

  return (
    <FlexCol fullWidth fullHeight paddingLevel={3}>
      <FlexCol style={{ flexGrow: 1 }}>
        <FlexRow vAlign="center" itemSpacing={3}>
          <Title>File Pipeline Failed Files</Title>
          {pipelineFailuresLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Title>
              ({searchText !== "" && `${foundCount} of `}
              {pipelineFailedFiles.length})
            </Title>
          )}
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={showResolved} />}
              label="Show resolved"
              onClick={() => setShowResolved(!showResolved)}
              style={{ flexGrow: 0, flexWrap: "nowrap" }}
              title="include files that ultimately had success"
            />
          </FormGroup>
          <TableSearchBar
            searchText={searchText}
            handleSearch={searchText => setSearchText(searchText)}
          />
          <IconButton
            size="small"
            onClick={() => dispatch(queryPipelineFailures())}
            title="Refetch"
          >
            <IconRefresh />
          </IconButton>
        </FlexRow>
        <FlexCol style={{ flexGrow: 1 }}>
          <FailedFilesTable
            searchText={searchText}
            failedFiles={pipelineFailedFiles}
            getFoundCount={setFoundCount}
            showHistoryFn={setShowHistory}
          />
          {(pipelineFailuresLoading || Boolean(pipelineFailuresError)) && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backdropFilter: "blur(2px)",
              }}
            >
              {pipelineFailuresLoading && <SpinnerInnovasea />}
              {pipelineFailuresError && (
                <span title={pipelineFailuresError}>
                  <IconError />
                </span>
              )}
            </div>
          )}
        </FlexCol>
      </FlexCol>
      <DialogWrapper
        title="File failure history"
        open={showHistory.length > 0}
        cancelAction={() => setShowHistory([])}
        maxWidth="xl"
      >
        <FlexCol style={{ minHeight: 500 }}>
          <FailedFilesTable failedFiles={showHistory} />
        </FlexCol>
      </DialogWrapper>
    </FlexCol>
  );
}
