import { Thunk } from "../../common";
import { PipelineAction } from "./pipeline-types";
import { snackbarError } from "../../snackbar/snackbar-actions";
import { callGqlApi } from "../../../helpers/api";
import gql from "../../gqlTag";

export function queryPipelineFailures(): Thunk<void, PipelineAction> {
  return async dispatch => {
    dispatch({ type: "PIPELINE_FAILURES_START_LOADING_DATA" });

    const gqlPipelineFailures = gql`
      query {
        pipelineFailures {
          id
          fileId
          fileName
          workspaceId
          errorPhase
          errorStep
          errorMessage
          creationTime
          updateTime
          mergeInfo {
            mergeTime
            mergeStatus {
              phaseStatusId
              phaseStatus
            }
          }
        }
      }
    `;
    callGqlApi(gqlPipelineFailures)
      .then(res => {
        dispatch({ type: "PIPELINE_FAILURES_SET_DATA", payload: { list: res.pipelineFailures } });
      })
      .catch(errors => {
        console.error(errors);
        dispatch({ type: "PIPELINE_FAILURES_ERROR", payload: { message: errors.message } });
        dispatch(
          snackbarError(`Error fetching file pipeline failure data: ${errors.message}`) as any
        );
      });
  };
}
