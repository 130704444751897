import React, { createRef } from "react";
import memoize from "memoize-one";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { FixedSizeList } from "react-window";

import Row from "./Row";
import HeaderRow from "./HeaderRow";

import { sortByColumn, applyColumnFilters, applyTextSearch } from "./filtering_sorting";
import throttle from "lodash";
import { removeByIndex } from "../../helpers/common";
import ResizeHandler from "../../../components/common/ResizeHandler";

// event keyCodes
const UP_ARROW = 38;
const DOWN_ARROW = 40;
// throttling in ms for scrolling with arrow keys
const SCROLL_THROTTLE = 125;

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  tableBody: {
    width: "100%",
    position: "relative",
    flexGrow: 1,
  },
};

class WindowedTable extends React.Component {
  state = {
    sortBy: this.props.initialSortBy,
    sortDir: this.props.initialSortDir,
    colFilters: {},
    rowClientWidth: null,
    tableHeight: 1000,
    tableWidth: "100%",
    shiftAnchor: null,
  };

  listRef = createRef();

  listOuterRef = null;
  setListOuterRef = element => {
    this.listOuterRef = element;
    this.setState({ rowClientWidth: element && element.clientWidth });
  };

  /* Generate a unique DOM id for this instance based on the row index
   * This is used for finding a row element and focusing on it.
   * Couldn't find a way to accomplish this with refs. */
  getDOMId = (() => {
    const instanceId = Math.random().toString();
    return index => instanceId + "-" + index;
  })();

  // Filtering, Sorting, Data Transformation

  sortByColumnMemo = memoize(sortByColumn);
  applyColumnFiltersMemo = memoize(applyColumnFilters);
  applyTextSearchMemo = memoize(applyTextSearch);

  sortAndFilterRows = memoize((rows, columns, searchText, sortBy, sortDir, colFilters) => {
    const sortedRows = this.sortByColumnMemo(rows, columns, sortBy, sortDir);
    const columnFilteredRows = this.applyColumnFiltersMemo(sortedRows, columns, colFilters);
    return this.applyTextSearchMemo(columnFilteredRows, columns, searchText);
  });

  getDisplayedRows = () => {
    const { rows, columns, searchText, getDisplayedCount } = this.props;
    const { sortBy, sortDir, colFilters } = this.state;
    const displayedRows = this.sortAndFilterRows(
      rows,
      columns,
      searchText,
      sortBy,
      sortDir,
      colFilters
    );
    getDisplayedCount?.constructor === Function && getDisplayedCount(displayedRows?.length);
    return displayedRows;
  };

  // Handle a click on the header of the select column
  handleSelectHeader = () => {
    const { selection, rowIdKey, disabledIds } = this.props;
    const displayedRows = this.getDisplayedRows();
    const allSelected = selection.length === displayedRows.length - disabledIds.length;

    const newSelection = allSelected
      ? []
      : displayedRows.map(row => row[rowIdKey]).filter(id => !disabledIds.includes(id));

    this._onSelect(newSelection);
  };

  _onSelect = (selection, newShiftAnchor) => {
    this.props.onSelect(selection);

    if (newShiftAnchor !== undefined) {
      this.setState({ shiftAnchor: newShiftAnchor });
    }
  };

  /* Transforms the selection list into an extra boolean field on the rows.
   * It turns out this works much more efficiently with react-virtualized, compared to
   * calculating the value as needed. */
  addSelectCol = memoize((rows, selection, rowIdKey, disabledIds) => {
    const preppedRows = [...rows];
    preppedRows.forEach(row => {
      const disabled = disabledIds ? disabledIds.includes(row[rowIdKey]) : false;
      row.__selected = !disabled ? selection.includes(row[rowIdKey]) : false;
      row.__selectDisabled = disabled;
    });
    return preppedRows;
  });

  // UI Event Handling

  getShiftSelection = rowId => {
    const { selection, rowIdKey, disabledIds } = this.props;
    const { shiftAnchor } = this.state;

    if (shiftAnchor === null || shiftAnchor === undefined) {
      return [rowId];
    }

    const displayedRows = this.getDisplayedRows();
    const startIdx = displayedRows.findIndex(row => row[rowIdKey] === shiftAnchor);
    const endIdx = displayedRows.findIndex(row => row[rowIdKey] === rowId);
    const idxRange = [startIdx, endIdx].sort((a, b) => a - b);
    const shiftRows = displayedRows
      .slice(idxRange[0], idxRange[1] + 1)
      .map(row => row[rowIdKey])
      .filter(id => !disabledIds.includes(id));

    return [...new Set([...selection, ...shiftRows])];
  };

  handleCheckbox = (rowId, shiftKey) => {
    const { selection } = this.props;
    const idx = selection.indexOf(rowId);
    const alreadySelected = idx > -1;

    const newSelection = alreadySelected
      ? removeByIndex(selection, idx)
      : shiftKey
      ? this.getShiftSelection(rowId)
      : [...selection, rowId];

    this._onSelect(newSelection, rowId);
  };

  handleRowClick = (rowId, shiftKey) => {
    const disabled = this.props.disabledIds && this.props.disabledIds.includes(rowId);
    if (!disabled) {
      if (this.props.selectOnRowClick) {
        const newSelection = shiftKey ? this.getShiftSelection(rowId) : [rowId];
        this._onSelect(newSelection, rowId);
      } else {
        this.setState({ shiftAnchor: rowId });
      }
    }
  };

  handleSort = colKey => {
    const alreadySortedBy = colKey === this.state.sortBy;
    const sortDir = alreadySortedBy ? (this.state.sortDir === "ASC" ? "DESC" : "ASC") : "ASC";
    this.setState({ sortBy: colKey, sortDir });
  };

  handleColFilter = (dataKey, value) => {
    const colFilters = { ...this.state.colFilters, [dataKey]: value };
    if (value === "") {
      delete colFilters[dataKey];
    }
    this.setState({ colFilters });
  };

  handleKeyDown = throttle(
    ({ keyCode }) => {
      if (keyCode === UP_ARROW || keyCode === DOWN_ARROW) {
        const { selection, rowIdKey, disabledIds } = this.props;
        const displayedRows = this.getDisplayedRows();
        const disabledIndexes = displayedRows.reduce((indexes, row, i) => {
          if (disabledIds.includes(row[rowIdKey])) {
            indexes.push(i);
          }
          return indexes;
        }, []);

        // No selection: default to first element. Otherwise, choose adjacent element.
        let newIndex = 0;
        if (selection.length > 0) {
          const lastSelectedId = selection[selection.length - 1];
          const lastSelectedIndex = displayedRows.findIndex(r => r[rowIdKey] === lastSelectedId);

          // Select the next index going up or down skipping over any disabled indexes
          if (keyCode === UP_ARROW) {
            let nextUpIndex = lastSelectedIndex - 1;
            while (disabledIndexes.includes(nextUpIndex) && nextUpIndex > 0) {
              nextUpIndex--;
            }
            newIndex = Math.max(nextUpIndex, 0);
          } else {
            let nextDownIndex = lastSelectedIndex + 1;
            while (
              disabledIndexes.includes(nextDownIndex) &&
              nextDownIndex < displayedRows.length - 1
            ) {
              nextDownIndex++;
            }
            newIndex = Math.min(nextDownIndex, displayedRows.length - 1);
          }
        }

        const newSelectedId = displayedRows[newIndex][rowIdKey];

        // select the next row
        this._onSelect([newSelectedId], newSelectedId);
        // scroll to the next row (if necessary)
        this.listRef.current.scrollToItem(newIndex);

        // focus on the next row (without scrolling)
        const rowEl = document.getElementById(this.getDOMId(newIndex));
        const scrollTop = this.listOuterRef.scrollTop;
        setTimeout(() => {
          rowEl.focus();
          this.listOuterRef.scrollTop = scrollTop;
        }, 0);
      }
    },
    SCROLL_THROTTLE,
    { leading: true, trailing: false }
  );

  disableScroll = event => {
    // this is separate from handleKeyDown because it cannot be throttled
    if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
      event.preventDefault();
    }
  };

  onFocus = () => {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keydown", this.disableScroll);
  };

  onBlur = () => {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keydown", this.disableScroll);
  };

  compileItemData = memoize(
    (finalRows, columns, selectable, handleRowClick, handleCheckbox, rowIdKey, getDOMId) => ({
      rows: finalRows,
      /* these "props" are passed down to the Row component. react-window expects a component for rendering items,
       * but only passed the following props to that component: { style, index, itemData }. So any extra props have
       * to be put inside itemData. This strange API is planned to be changed in the next version of react-window. */
      _props: { columns, selectable, handleRowClick, handleCheckbox, rowIdKey, getDOMId },
    })
  );

  componentDidUpdate(prevProps) {
    this.updateRowClientWidth();

    // clear sortBy if the column has been removed
    const { columns } = this.props;
    if (columns !== prevProps.columns && !columns.find(col => col.dataKey === this.state.sortBy)) {
      this.setState({ sortBy: null });
    }
  }

  /* Check the clientWidth of the table body, which does _not_ include the scrollbar width.
   * If it has changed, update rowClientWidth. This ensures that the header width matches
   * the table body width even as the scrollbar appear and disappears. */
  updateRowClientWidth = (() => {
    let prevRowClientWidth = null;
    return () => {
      const rowClientWidth = this.listOuterRef && this.listOuterRef.clientWidth;
      if (prevRowClientWidth !== rowClientWidth) {
        this.setState({ rowClientWidth });
        prevRowClientWidth = rowClientWidth;
      }
    };
  })();

  render() {
    const { columns, rowIdKey, selectable, selection, rowHeight, headerHeight, classes, disabledIds } = this.props; // prettier-ignore
    const { sortBy, sortDir, colFilters, rowClientWidth, tableHeight, tableWidth } = this.state;

    const displayedRows = this.getDisplayedRows();

    const finalRows = selectable
      ? this.addSelectCol(displayedRows, selection, rowIdKey, disabledIds)
      : displayedRows;

    const itemData = this.compileItemData(
      finalRows,
      columns,
      selectable,
      this.handleRowClick,
      this.handleCheckbox,
      rowIdKey,
      this.getDOMId
    );
    const allSelectDisabled = finalRows.length === disabledIds.length;

    return (
      <div onFocus={this.onFocus} onBlur={this.onBlur} className={classes.root}>
        <HeaderRow
          columns={columns}
          height={headerHeight}
          width={rowClientWidth}
          selectable={selectable}
          selectDisabled={allSelectDisabled}
          handleSelectHeader={this.handleSelectHeader}
          allSelected={finalRows.length - disabledIds.length === selection.length}
          sortBy={sortBy}
          sortDir={sortDir}
          handleSort={this.handleSort}
          colFilters={colFilters}
          handleColFilter={this.handleColFilter}
        />
        <div className={classes.tableBody}>
          <ResizeHandler
            onResize={(width, height) => {
              this.setState({ tableHeight: height, tableWidth: width });
              this.updateRowClientWidth();
            }}
          >
            <FixedSizeList
              height={tableHeight}
              width={tableWidth}
              itemCount={finalRows.length}
              itemData={itemData}
              itemSize={rowHeight}
              overscanCount={10}
              ref={this.listRef}
              outerRef={this.setListOuterRef}
              style={{ overflowY: "scroll" }} // this may seem unnecessary, but without it the table does not shrink horizontally unless it overflows vertically.
            >
              {Row}
            </FixedSizeList>
          </ResizeHandler>
        </div>
      </div>
    );
  }
}

WindowedTable.defaultProps = {
  selection: [],
  disabledIds: [],
  rowHeight: 32,
  headerHeight: 40,
  initialSortDir: "ASC",
};

WindowedTable.propTypes = {
  // array of identically shaped records that contain the data to be rendered
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  // unique key used to identify row records
  rowIdKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // array of objects specifying how columns will be rendered from the row data
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // User-facing column header label
      label: PropTypes.string,
      // Unique key that identifies the column. Used by default extract data from row for rendering
      dataKey: PropTypes.string.isRequired,
      /* Optional function to render the cell content. Takes one argument: row data.
       * Default: rowData => rowData[dataKey] */
      renderFn: PropTypes.func,
      // If true, column will be right justified & default sort function will be numeric sort
      numeric: PropTypes.bool,

      // Default width of column, subject to flexbox rules
      width: PropTypes.number.isRequired,
      // flex-grow css attribute on the column's cells. Used to control dynamic column widths
      flexGrow: PropTypes.number,
      // flex-grow css attribute on the column's cells. Used to control dynamic column widths
      flexShrink: PropTypes.number,
      // By default, cells do not wrap on whitespacce, and indicate overflow with ellipses. This options disables that behaviour.
      noEllipses: PropTypes.bool,

      // Columns are sortable by default. Set this prop to true to disable sorting for this column
      disableSort: PropTypes.bool,
      /* Optional function to override the default sort function.
       * Function is of the same form as used in Array.sort. e.g. (a, b) => (a - b)
       * See filtering_sorting.js for default sort function. */
      sortFn: PropTypes.func,

      // If true, column-specific filtering will be enabled
      filterable: PropTypes.bool,
      /* A custom component to render for column-specific filtering.
       * Should forward ref & accept 3 props: ref, value, onChange.
       * E.g
       * React.forwardRef(({ value, onChange }, ref) => (
       *   <input ref={ref} value={value} onChange={ e => onChange(e.target.value) } />
       * ))
       * Default is a text input. */
      FilterInputComponent: PropTypes.elementType,
      /* Optional function to override the default filter function.
       * Default is case-insensitive text filtering.
       * The filter function should take one argument (the test value) and return a function of one
       * argument (the cell value). This allows pre-processing of the test value to be performed once
       * instead of for each row.
       * Function signature: (testValue) => (cellValue) => Boolean */
      filterFn: PropTypes.func,

      /* If true, on hovering over a cell in this column  an edit button will appear.
       * When clicked this button will fire onEdit */
      editable: PropTypes.bool,
      /* Callback fired when edit function is clicked.
         Callback signature: ({ rowId, value }) => {..} */
      onEdit: PropTypes.func, // callback fired when edit icon is clicked. Callback should accept (event, rowData)
    })
  ).isRequired,

  /* The column (specified by dataKey) that the table will be sorted by on first render.
  /* Ignored if column is not sortable. */
  initialSortBy: PropTypes.string,
  // the initial sort direction
  initialSortDir: PropTypes.oneOf(["ASC", "DESC"]),

  // Height of the header in pixels. See above for default value.
  headerHeight: PropTypes.number,
  // Height of each row in pixels. See above for default value.
  rowHeight: PropTypes.number,

  /* If true, an extra column will be added to the table to allow the user to select & deselect columns.
   * The onSelect callback will be fired when the selection changes.
   * The selection state must be managed by the parent component. */
  selectable: PropTypes.bool,
  // Array of rowIds indicating which are currently selected. Ignored if selectable == false.
  selection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  /* Callback fired when the row selected changes. Ignored if selectable == false.
   * Callback singature: (selection) => {..} */
  onSelect: PropTypes.func,
  // rows that are disabled from selection
  disabledIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  // If true, clicking anywhere on a row (but not on the select column) will select only that row.
  selectOnRowClick: PropTypes.bool,
  // initialVisibleCols: PropTypes.arrayOf(PropTypes.string), // array of column dataKeys that defines initially displayed columns
  searchText: PropTypes.string,
  getDisplayedCount: PropTypes.func,
};

export default withStyles(styles)(WindowedTable);
