import { Tooltip } from "@material-ui/core";
import { WindowedTable } from "../../../fathom-brella";
import { formatDateTime } from "../../../helpers/time";
import { PipelineFailure, FailedFile } from "../../../redux/admin/pipeline/pipeline-types";

export function FailedFilesTable({
  searchText,
  failedFiles,
  getFoundCount,
  showHistoryFn,
}: {
  failedFiles: PipelineFailure[] | FailedFile[];
  searchText?: string;
  getFoundCount?: (num: number) => void;
  showHistoryFn?: (history: PipelineFailure[]) => void;
}) {
  const showHistory = showHistoryFn ? showHistoryFn : () => {};
  return (
    <WindowedTable
      searchText={searchText}
      getDisplayedCount={getFoundCount}
      rows={failedFiles}
      rowIdKey={"id"}
      columns={[
        {
          width: 400,
          dataKey: "fileName",
          label: "File Name",
          renderFn: (fileName, row) => (
            <Tooltip
              title={`${
                showHistoryFn ? "Click to see failure history for file:  " : ""
              }${fileName}`}
            >
              <span style={{ width: "100%" }} onClick={() => showHistory(row.history)}>
                {fileName}
              </span>
            </Tooltip>
          ),
        },
        {
          width: 90,
          dataKey: "workspacId",
          label: showHistoryFn ? "Workspaces" : "WorkspaceId", // if no showHistoryFn then this is history which is workspace specific
          renderFn: (_, row) => (showHistoryFn ? row.workspaces : row.workspaceId),
        },
        { width: 80, dataKey: "errorPhase", label: "Phase" },
        { width: 90, dataKey: "errorStep", label: "Step" },
        {
          width: 150,
          dataKey: "updateTime",
          label: "Update Time",
          renderFn: updateTime => formatDateTime(updateTime),
        },
        {
          width: 350,
          dataKey: "errorMessage",
          label: "Error Message",
          renderFn: errorMessage => (
            <Tooltip title={errorMessage}>
              <span>{errorMessage}</span>
            </Tooltip>
          ),
        },
      ]}
    />
  );
}
